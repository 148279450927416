import { ElNotification } from 'element-plus';
import { h } from 'vue';

export function useNotification() {
  const openNotBottom = (val, type) => {
    let customIcon;
    switch (type) {
      case 'success':
        customIcon = h(
          'svg',
          {
            viewBox: '0 0 16 17',
            xmlns: 'http://www.w3.org/2000/svg',
            style: 'width: 20px; height: 20px;'
          },
          [
            h('path', {
              d: 'M7.99998 1.83325C4.31998 1.83325 1.33331 4.81992 1.33331 8.49992C1.33331 12.1799 4.31998 15.1666 7.99998 15.1666C11.68 15.1666 14.6666 12.1799 14.6666 8.49992C14.6666 4.81992 11.68 1.83325 7.99998 1.83325ZM6.66665 11.8333L3.33331 8.49992L4.27331 7.55992L6.66665 9.94659L11.7266 4.88659L12.6666 5.83325L6.66665 11.8333Z'
            })
          ]
        );
        break;
      case 'error':
        customIcon = h(
          'svg',
          {
            viewBox: '0 0 20 21',
            xmlns: 'http://www.w3.org/2000/svg',
            style: 'width: 20px; height: 20px;'
          },
          [
            h('path', {
              d: 'M10 0.439453C4.48 0.439453 0 4.91945 0 10.4395C0 15.9595 4.48 20.4395 10 20.4395C15.52 20.4395 20 15.9595 20 10.4395C20 4.91945 15.52 0.439453 10 0.439453ZM11 15.4395H9V13.4395H11V15.4395ZM11 11.4395H9V5.43945H11V11.4395Z'
            })
          ]
        );
        break;
      default:
        break;
    }

    ElNotification({
      message: h('div', { style: 'display: flex; align-items: center;', class: `not-icon-${type}` }, [
        customIcon,
        h('span', { style: 'margin-left: 8px;' }, val)
      ]),
      position: 'bottom-right',
      duration: 5000
    });
  };
  return {
    openNotBottom
  };
}
