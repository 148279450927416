<template>
    <div class="crppper-modal">
        <div class="upload">
            <label for="file-input" class="img-wrap">
                <div class="img-wrap-placeholder">
                    <div class="image-placeholder">
                        <p class="w-100">Click here to select a image</p>
                        <el-button size="large" @click="fileInput.click()">
                            <svg-icon name="plus" />
                            SELECT IMAGE
                        </el-button>
                    </div>
                    <input id="file-input" class="avatar-input" type="file" ref="fileInput" @change="loadImage($event)"
                        accept="image/png, image/jpeg" hidden>
                </div>
            </label>

            <cropper :class="[img_data.src && 'vue-advanced-cropper-class']" ref="cropperRef"
                :default-size="defaultSize" @change="onChange" :canvas="{
                    minHeight: 0,
                    minWidth: 0,
                    maxWidth: 670,
                    maxHeight: 370,
                }" :src="img_data.src" />
        </div>

        <div class="tools" v-if="img_data.src">
            <div class="t-desc ">zoom</div>
            <i :class="[scale_current === 0 && 'disabeld']" @click="handleScaleValue(1)"><svg-icon name="less" /></i>
            <el-slider v-model="scale_current" :step="1" :max="5" :show-tooltip="false" />
            <i :class="[scale_current === 10 && 'disabeld']" @click="handleScaleValue(0)"><svg-icon name="plus" /></i>
            <div class="tools-right">
                <div class="t-desc ">Rotate</div>
                <div class="icon" @click="handleRotate(-90)"><svg-icon name="rotate" /></div>
                <div class="icon" @click="handleRotate(90)"><svg-icon name="rotate-right" /></div>
            </div>

        </div>

        <p class="err-text" v-if="avatarErr"> {{ avatarErr }}</p>

        <footer class="upload-footer">
            <el-button size="large" @click="fileInput.click()">Change Image</el-button>
            <el-button size="large" type="primary" @click="save()" :is_disabled="!img_data.src">Save</el-button>
        </footer>
    </div>
</template>

<script setup>
import { ref, reactive, watch, onMounted } from 'vue';
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import getMimeType from '@/utils/getMimeType';
import { ElButton, ElSlider } from 'element-plus';

const props = defineProps({
});

const emit = defineEmits(['saveAvatar']);

const fileInput = ref(null);
const avatarErr = ref('');
const scale_current = ref(0);
const img_data = ref({
    src: null,
    data: null,
    type: null
});
const img_rotate = ref(0);
const is_loading = ref(false);

const cropperRef = ref(null);//注意命名不可以是cropper

watch(scale_current, (newVal, oldVal) => {
    if (newVal > oldVal) {
        handleZoomFun(1);
    } else {
        handleZoomFun(0);
    }
});

const handleScaleValue = (val) => {
    if (val && scale_current.value > 0) {
        scale_current.value--;
    } else if (!val && scale_current.value < 5) {
        scale_current.value += 1;
    }
};

const handleZoomFun = (val) => {
    let value = false;
    if (scale_current.value > 0 && scale_current.value < 5) {
        if (val) {
            value = (val + scale_current.value * 0.2).toFixed(1);
        } else {
            value = updateScaleFactor(scale_current.value).toFixed(1);
        }
    } else if (scale_current.value === 5) {
        value = 2;
    } else if (scale_current.value === 0) {
        value = 0;
    }
    console.log('value', value)
    if (value !== false) {
        cropperRef.value.zoom(value);
    }
};

const updateScaleFactor = (scale) => {
    const scaleMap = {
        4: 0.9,
        3: 0.8,
        2: 0.7,
        1: 0.6,
    };
    return scaleMap[scale] || 0;
};

const handleRotate = (val) => {
    cropperRef.value.rotate(val);
};

const loadImage = (event) => {
    const { files } = event.target;

    if (files && files[0]) {
        const isJpgOrPng = files[0].type === 'image/jpeg' || files[0].type === 'image/png';
        const isLt2M = files[0].size / 1024 / 1024 < 1; // 檔案小於1MB限制

        if (!isJpgOrPng) {
            avatarErr.value = 'Only JPG or PNG images are allowed.';
            return false;
        }

        if (!isLt2M) {
            avatarErr.value = 'Uploaded images must not exceed 1MB.';
            return false;
        }

        if (img_data.value.src) {
            URL.revokeObjectURL(img_data.value.src);
        }

        const blob = URL.createObjectURL(files[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
            img_data.value.src = blob;
            img_data.value.data = files[0];
            img_data.value.type = getMimeType(e.target.result, files[0].type);
        };
        reader.readAsArrayBuffer(files[0]);
    }

};

const defaultSize = ({ imageSize }) => {
    return {
        width: Math.min(imageSize.height, imageSize.width),
        height: Math.min(imageSize.height, imageSize.width),
    };
};

const onChange = (result) => {
};

const save = () => {
    const { canvas } = cropperRef.value.getResult();
    canvas.toBlob((blob) => {
        img_data.value.data = blob;
        img_data.value.src = URL.createObjectURL(blob);
        emit('saveAvatar', img_data.value);
    }, img_data.value.type);
};


const start = () => {
    fileInput.value.click();
};

onMounted(() => {
    start();
});
</script>

<style lang="scss">
@mixin flexCenter() {
    display: flex;
    align-items: center;
}
$mobile_size: 768px;
.crppper-modal {

    .img-wrap {
        @include flexCenter();
        justify-content: center;
        width: 50%;
        margin: 35px auto;
        height: 300px;
        border: 2px dashed var(--Black-40);
        border-radius: 5px;
        position: relative;
        cursor: pointer;

        .image-container img {
            max-width: 100%;
            max-height: 100%;
            object-fit: contain;
        }

        .image-placeholder {
            display: grid;
            justify-content: center;
            flex-wrap: wrap;
            text-align: center;
            gap: 16px;

            svg {
                fill: #555;
                width: 12px;
                height: 12px;
                margin-right: 8px;
            }

            p {
                font-size: 16px;
                color: #555;
            }
        }

        .avatar-input {
            display: none;
        }

        @media (max-width: $mobile_size) {
            width: 90%;
        }
    }

    .vue-advanced-cropper {
        position: absolute;
        left: 0;
        top: 0;

        &.vue-advanced-cropper-class {
            height: 370px;
            width: 100%;
            background-color: var(--Black-40);
        }
    }

}

.crppper-modal {
    .upload {
        width: 100%;
        height: 370px;
        position: relative;
        background-color: var(--Bg-01);
        overflow: hidden;

        .loading {
            position: absolute;
        }
    }

    .err-text {
        font-size: 16px;
        padding: 16px 16px 0 16px;
        color: var(--Red-30);
    }

    .upload-footer {
        @include flexCenter();
        justify-content: flex-end;
        padding: 24px 16px;
        gap: 8px;
    }


    .tools {
        display: flex;
        align-items: center;
        border: solid 1px var(--Black-40);

        .t-desc {
            margin-left: 16px;
        }

        .tools-right {
            display: flex;
            align-items: center;
            border-left: solid 1px var(--Black-40);
            height: 100%;
            padding: 8px;
            margin-right: 16px;

            .t-desc {
                margin-left: 4px;
                margin-right: 12px;
            }
        }

        &.disabled {
            opacity: 0.5;
            pointer-events: none;
        }

        .icon {
            height: 24px;
            width: 24px;
            min-width: 24px;
            border-radius: 50%;
            border: 1px solid var(--PB-100);
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            &:first-child {
                margin-right: 10px;
            }

            &:last-child {
                margin-left: 10px;
            }

            svg {
                height: 14px;
                width: 14px;
                fill: var(--PB-100);
            }
        }

        i {
            cursor: pointer;

            svg {
                height: 16px;
                width: 16px;
                fill: var(--PB-100);
                margin: 8px;
            }
        }

        .ui-slider {
            flex: 1;
            position: relative;
            margin: 0;
            padding: 8px 0;
        }

        @media (max-width: $mobile_size) {
            flex-wrap: wrap;

            .tools-right {
                width: 100%;
                border-top: solid 1px var(--Black-40);
                margin: 0;
            }
        }
    }
}
</style>
