<template>
  <div class="status-box"
      :class="['class-' + status ]" >
      {{ props.outside_status_text ? props.outside_status_text: status_text }}
  </div>
</template>

<script setup>
import { useSlots,computed } from 'vue';
const slots = useSlots();

const props = defineProps({
  status: {
    type: String || Number,
    default: 0
  },
  outside_status_text:{
    type: String,
    default: ''
  }
});

const status_text =computed(()=> {
    let text='';
    switch (props.status) {
        case 0:
            text = 'Not Connected';
            break;
        case 20:
            text = 'Active';
            break;
        case 25:
            text = 'Expired';
            break;
    }
    return text;
});
    
</script>
<style lang="scss">
.status-box {
  color: var(--Black-100);
  font-size: 12px;
  display: inline-flex;
  align-items: center;
  line-height: 18px;

  &::before {
      height: 8px;
      width: 8px;
      content: "";
      display: block;
      border-radius: 50%; /* 完整圓形 */
      background: var(--Yellow-30);
      border: 1px solid var(--Yellow-30); /* 整個圓的邊框 */
      margin-right: 5px;
  }
  
  //->20全綠->25全紅->30->全藍->0全灰(預設全黃)
  &.class-20{
      &::before {
          background: var(--Green-30);
          border: 1px solid var(--Green-30);
      }
  }


  &.class-25{
      &::before {
          background:var(--Red-30);
          border: 1px solid var(--Red-30);
      }
  }

  &.class-30{
      &::before {
          background:var(--Blue-30);
          border: 1px solid var(--Blue-30);
      }
  }

  &.class-0{
      &::before {
          background: var(--Neu-30); 
          border: 1px solid var(--Neu-30); /* 整個圓的邊框 */
      }
  }
}
</style>
