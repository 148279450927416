<template>
  <div class="pick-date-box">
    <time>
      <DatePickerNext
        :value="value.date"
        type="date"
        :disabled="disabled"
        :format="format"
        :placeholder="format"
        :disabled-date="cantPickDate"
        @change="calendarChange"
        :popup-class="'mx-datepicker-main-spec'"
      />
    </time>
    <time>
      <DatePickerNext
        :value="value.time"
        type="time"
        :disabled="disabled || !value.date"
        :format="`h:mm A`"
        :placeholder="`h:mm A`"
        :disabled-date="cantPickDate"
        @change="timeChange"
        :popup-class="'mx-datepicker-main-spec'"
      />
    </time>
  </div>
</template>

<script>
import DatePickerNext from "vue-datepicker-next";
import "vue-datepicker-next/index.css";
import SvgIcon from "@/components/ui/SvgIcon.vue";
import Button from "@/components/ui/Button.vue";
import moment from "moment";

export default {
  name: "DateTimePicker",
  emits: ['update-time','update-date'],
  components: {
    DatePickerNext,
    SvgIcon,
    Button,
  },

  computed: {},

  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    date: {
      type: [Date, String,Number],
      default: null,
    },

    time: {
      type: [Date, String,Number],
      default: null,
    },

    datetime:{
      type: [Date, String,Number],
      default: null,
    },

    format: {
      type: String,
      default: "MM/DD/YYYY",
    },

    spec_val: {
      type: String,
      default: "",
    },

    index:{
      type: [String,Number],
      default: null,
    },

    max_date: {
      type: [Date, String,Number],
      default: null,
    },

    min_date: {
      type: [Date, String,Number],
      default: null,
    },
  },

  data() {
    return {
      value: {
        date: "",
        time: "",
      },
      date_value: {
        key: 0,
        maxDate: null,
        minDate: null,
      },
      format_time: `h:mm A`,
      key: 0,
    };
  },

  watch: {},

  methods: {
    calendarChange(date) {
      const obj = {};
      this.value.date=date;
      obj.key = this.spec_val;
      obj.index= this.index;
      obj.val = moment(date).format(this.format);
      this.$emit("update-date", obj);
    },

    timeChange(time) {
      const obj = {};
      this.value.time=time;
      obj.key = this.spec_val;
      obj.index= this.index;
      obj.val = moment(time).format(this.format_time);
      this.$emit("update-time", obj);
    },

    cantPickDate(date) {
      if (this.max_date) {
        this.date_value.maxDate = this.max_date;
      }
      if (this.min_date) {
        this.date_value.minDate = this.min_date;
      }
      let maxDate = this.date_value.maxDate
        ? new Date(this.date_value.maxDate)
        : null;
      let minDate = this.date_value.minDate
        ? new Date(new Date(this.date_value.minDate).getTime())
        : null;

      if (maxDate && minDate) {
        // 同時檢查最大時間和最小時間
        return date < minDate || date > maxDate;
      } else if (maxDate) {
        // 只有最大時間限制
        return date > maxDate;
      } else if (minDate) {
        // 只有最小時間限制
        return date < minDate;
      } else {
        // 沒有時間限制
        return false;
      }
    },
  },

  mounted() {
    if (this.date && this.datetime) {
      this.value.date = moment(this.datetime).toDate();
    }

    if (this.time && this.datetime) {
      this.value.time = moment(this.datetime).toDate();
    }
  },
};
</script>
<style lang="scss">
.pick-date-box {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 4px;

  .mx-datepicker {
    width: unset !important;
  }
}
</style>
