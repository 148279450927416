<template>
  <div class="editor-container">
    <Loading v-if="loading" />
    <Editor id="description" :apiKey="apiKey" v-model="content" :init="editorConfig" />
    <!-- <div class="character-counter">{{ charCount }}/100000</div> -->
  </div>
</template>

<script setup>
import { ref, onMounted, watch, getCurrentInstance } from "vue";
import { default as Editor } from "@tinymce/tinymce-vue";
import { useNotification } from '@/components/ui/notification';
import Loading from "@/components/ui/Loading.vue";
//import { useGlobalStore } from '@/stores/GlobalStore'

//const store = useGlobalStore();
const app = getCurrentInstance();
const ajax = app.appContext.config.globalProperties.$ajax;
const { openNotBottom } = useNotification();
const props = defineProps(["modelValue"]);
const emit = defineEmits(["update:modelValue"]);

// 富文本內容及字符計數
const content = ref(props.modelValue || "");
const apiKey = ref('c1alfret2hep8ly0ljv4bzkt8u257yn7p2w87xmdes22tsz4');
const loading = ref(false);

// TinyMCE 設定
const editorConfig = {
  apiKey: apiKey.value,
  height: 300,
  menubar: false,
  plugins: "tinydrive link image media",
  //| code | image media
  toolbar:
    "undo redo | bold italic underline | alignleft aligncenter alignright | bullist numlist | outdent indent | link image media",
  placeholder: "Lorem ipsum dolor sit amet consectetur.",
  statusbar: false, // 隱藏底部的狀態欄
  toolbar_location: "bottom", // 工具列位置移到編輯器下方
  image_title: true, // 啟用圖片標題
  automatic_uploads: false, // 自動上傳圖片
  notifications: false, // 關閉通知
  file_picker_types: "image media", // 支援圖片和影片
  // 圖片設定
  image_advtab: false,  // 關閉進階選項標籤
  image_uploadtab: false, // 關閉上傳標籤
  image_dimensions: true, // 尺寸設定
  image_class_list: false, // 關閉樣式類別選項
  media_dimensions: true, // 媒體尺寸設定
  media_live_embeds: true, // 允許媒體預覽
  media_alt_source: true, // 替代來源
  media_poster: true, // 打開預覽圖片設定
  media_filter_html: false, // 關閉 HTML 過濾
  extended_valid_elements: "video[*],source[*]",
  file_picker_callback: async (callback, value, meta) => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", meta.filetype === "image" ? "image/*" : "video/*");

    input.onchange = async () => {
      const file = input.files[0];
      try {
        const blob = await loadImage(file);
        // 關鍵修改：直接傳入 blob URL，而不是完整的 HTML 字符串
        if (meta.filetype === "video") {
          callback(blob.src, {
            title: file.name
          });
        } else {
          callback(blob.src, {
            title: file.name,
            alt: file.name
          });
        }

      } catch (error) {
        openNotBottom(error, 'error');
      }
    };
    input.click();
  },
  drag_drop: true, // 啟用拖放
  setup: (editor) => {
    // 拖放檔案
    editor.on("drop", async (e) => {
      // 如果是從外部拖入新檔案
      if (e.dataTransfer.files.length > 0) {
        const file = e.dataTransfer.files[0];
        if (file && file.type.startsWith("video/")) {
          e.preventDefault();
          try {
            const blob = await loadImage(file);
            const insertedContent = `<video controls src="${blob.src}"></video>`;
            editor.insertContent(insertedContent);
          } catch (error) {
            openNotBottom(error, 'error');
          }
        }
        else {
          e.preventDefault();
          try {
            const blob = await loadImage(file);
            const insertedContent = `<img src="${blob.src}" alt="${file.name}" title="${file.name}" />`;
            editor.insertContent(insertedContent);
          } catch (error) {
            openNotBottom(error, 'error')
          }
        }
      }
      // 如果是編輯器內部的拖曳，不做處理讓 TinyMCE 自己處理
    });

    const updateContent = () => {
      //console.log('editor.getContent()', editor.getContent());
      emit("update:modelValue", editor.getContent());
    };
    editor.on("keyup", updateContent);
    editor.on("change", updateContent);

    // 關閉特定類型的通知
    editor.on('BeforeOpenNotification', (e) => {
      e.preventDefault();
      return false;
    });
  }
};

//base64真的太大，做個暫緩
const loadImage = (file) => {
  return new Promise((resolve, reject) => {
    // 檢查檔案大小
    const isImage = file.type.startsWith('image/');
    const isVideo = file.type.startsWith('video/');

    // 轉換 MB 到 bytes (1MB = 1024 * 1024 bytes)
    const IMAGE_SIZE_LIMIT = 10 * 1024 * 1024; // 10MB
    const VIDEO_SIZE_LIMIT = 50 * 1024 * 1024; // 50MB

    if (isImage && file.size > IMAGE_SIZE_LIMIT) {
      reject('Uploaded images must not exceed 10MB.');
      return;
    }

    if (isVideo && file.size > VIDEO_SIZE_LIMIT) {
      reject('Uploaded images must not exceed 50MB.');
      return;
    }

    const uploadMedia = async () => {
      let formData = new FormData();
      formData.append('media', file);
      loading.value = true;
      try {
        const res = await ajax.post('announcement/upload-announcement-media', formData, {
          headers: { 'Content-Type': 'multipart/form-data' },
        });
        // 只返回 src
        resolve({
          src: res.data.data
        });
        loading.value = false;
      } catch (error) {
        openNotBottom(`Upload media failed.`, 'error');
        reject(`Upload media failed.`);
        loading.value = false;
      }
    };

    uploadMedia();
  });
};

//++++++++++++++++++
//mounted
//++++++++++++++++++
onMounted(() => {
  content.value = props.modelValue;
});
</script>

<style lang="scss">
@mixin flexCenter() {
  display: flex;
  align-items: center;
}

.editor-container {
  width: 100%;
  margin: auto;
  position: relative;
  font-family: var(--font);

  label {
    @include flexCenter;

    &::after {
      content: '*';
      color: var(--Red-30);
      margin-left: 4px;
      font: var(--medium-14);
    }
  }

  .character-counter {
    text-align: right;
    font-size: 12px;
    color: var(--Text-default);
    position: absolute;
    bottom: 52px;
    z-index: 2;
    right: 8px;
  }
}

//tinymce custom style
.tox-dialog {
  padding: 8px;
  font-family: var(--font-med);

  .tox-dialog__header {
    h1 {
      font: var(--medium-24);
    }
  }

  .tox-dialog__body-content {
    padding-bottom: 0;

    .tox-form {
      display: block;

      >.tox-form__group {
        margin-top: 16px;

        &:first-child {
          margin-top: 0;
        }

        .tox-label {
          font: var(--medium-16);
          color: var(--Text-primary);
          margin-bottom: 8px;
        }

        input:focus {
          border: 1px solid var(--dark-blue);
          outline: none;
          box-shadow: none;
        }
      }
    }
  }
}

.tox {
  .tox-button[aria-label="Save"] {
    background-color: var(--PB-100) !important;
    border-color: var(--PB-100) !important;
  }

  .tox-button {
    font: var(--medium-14);

    &::before {
      display: none;
    }
  }
}
</style>
