<template>
  <div class="no-messages">
    <div>
      <h3>Start chatting with the {{ props.type === 'creator' ? 'brand' : 'creator' }}</h3>
      <!-- <p>Start a new chat by clicking <strong>New chat</strong>.</p> -->
    </div>
  </div>
</template>

<script setup>
  const props = defineProps({
    type: {
      type: String,
      required: true
    },
  });
</script>

<style lang="scss">

  .no-messages {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 100%;
    padding: 0 16px;
    color: var(--black-90);
    max-width: 600px;
    margin: 0 auto;

    h3 {
        font: var(--medium-24);
        margin-bottom: 16px;
    }

    // p {
    //     font-size: 20px;
    //     color: var(--black-60);

    //     strong {
    //         font-weight: 700;
    //         color: var(--dark-blue);
    //         cursor: pointer;
    //     }
    // }
  }
</style>