<template>
  <section class="message-wrap">
    <header class="message-header">
      <slot name="header" />
    </header>
    <div class="message-content" ref="content">
      <div class="hr-end" v-if="isEnd">
        Chat with {{ props.type === 'creator' ? props.merchant.name : props.user.name }} started on 
        <span v-if="last_date">{{ moment(last_date).format('MMM DD, YYYY') }}</span>
        <span v-else>{{ moment().format('MMM DD, YYYY') }}</span>
      </div>
      <transition-group class="message-list" name="message-list" tag="div" v-if="messages.length !== 0">
        <el-button v-if="isLoadingMore" class="loading-btn" type="text" v-loading="true"></el-button>
        <template v-for="item in messages" :key="item.message_id">
          {{ greetingTime(item) }}
          <message-item 
            :item="item"
            :ajax="ajax"
            @retry-message="retryMessage"
            @mark-as-read="markAsRead"
            :id="'message-item--' + item.message_id"
          />
        </template>
      </transition-group>
      <no-message v-else :type="props.type" />
    </div>
    <footer class="message-footer">
      <!-- <span @click="forceDisconnect">Test error</span> -->
      <span class="error-ico" @click="connectWebSocket" v-if="statusType === 'error'">
        <svg-icon name="retry" /> Reconnect server
      </span>
      <el-input v-model="message" placeholder="Type messages" size="large" maxlength="500" @keyup.enter="sendMessage" />
      <el-tooltip 
        :content="message ? 'Send message' : 'Please type message'"
        placement="top">
        <el-button size="large" :disabled="!message" @click="sendMessage">
            <svg-icon name="send" />
        </el-button>
      </el-tooltip>
    </footer>
  </section> 

  <announcement-list v-if="props.type === 'creator'" :ajax="ajax" ref="announcementList" :merchant="merchant"  @open-announcement="openAnnouncement" />
  <announcement-detail :ajax="ajax" ref="announcementDetail" :merchant="merchant"/>
</template>

<script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  import { ElInput, ElTooltip, ElButton, ElLoading } from 'element-plus';
  import MessageItem from './MessageItem.vue';
  import NoMessage from './NoMessage.vue';
  import AnnouncementList from './AnnouncementList.vue';
  import AnnouncementDetail from './AnnouncementDetail.vue';
  import localforage from 'localforage';
  import log from '../../utils/Log';
  import moment from 'moment';
  
  let ws = null;
  let loading = null;
  const statusType = ref('connecting');
  const messages = ref([]);
  const message = ref('');
  const content = ref(null);
  const announcementList = ref(null);
  const announcementDetail = ref(null);
  const isEnd = ref(false);
  const isLoadingMore = ref(false);
  const prefix = 'chatroom_v1_';

  const props = defineProps({
    type: {
      type: String,
      required: true
    },
    token: {
      type: String,
      required: true
    },
    user: {
      type: Object,
      required: true
    },
    merchant: {
      type: Object,
      required: true
    },
    ajax: {
      type: Function,
      required: true
    }
  });

  const ajax = props.ajax;
  const emit = defineEmits(['get-message-history']);


  const uid = props.type + '_' + props.user.id + '_' + props.merchant.id;
  const last_date = ref('');

  localforage.config({
    driver: localforage.INDEXEDDB,
    name: 'chatroom',
    version: 1.0,
    storeName: 'messages',
    description: 'Anchor messages'
  });

  // window.localforage = localforage;
  // localforage.clear();
    
  localforage.getItem(prefix + uid + '_end').then((value) => {
    if (value) {
      isEnd.value = true;
    }
  });

  let last_active_date = '';
  const greetingTime = (item) => {
    // log('green', moment(last_active_date).format('YYYYMMDD') , moment(item.created_at).format('YYYYMMDD'));
    setTimeout(() => {
      if (moment(last_active_date).format('YYYYMMDD') !== moment(item.created_at).format('YYYYMMDD')) {
        last_active_date = item.created_at;
        const el = document.querySelector('#message-item--' + item.message_id);
        const html = `
          <div class="hr-date">
            <time>
              ${moment(item.created_at).format('MMM DD, YYYY')}
            </time>
          </div>
        `;
        if (el && el.classList.contains('is-added') === false) {
          el.classList.add('is-added');
          el.insertAdjacentHTML('beforebegin', html);
        }
      }
    }, 10);
  }

  const forceDisconnect = () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      ws.close(1000, 'Force disconnect');
      log('red', 'WebSocket connection closed by test');
    }
  };

  const connectWebSocket = () => {
    ws = new WebSocket('wss://www.creator.qa.anchor.store:23460');

    loading = ElLoading.service({
      lock: true,
      text: 'Connecting to Motom...',
      target: document.querySelector('.message-content')
    });


    log('green', 'WebSocket connecting');
    statusType.value = 'connecting';

    ws.onopen = () => onOpen();

    ws.onclose = () => onClose();

    ws.onerror = (error) => onError(error);

    ws.reconnect = () => onReconnect();

    ws.onreconnect = () => onReconnect();

    ws.onmessage = (event) => onMessage(event);
  }

  postMessage = (msg) => {
    if (ws === null || !ws.readyState || ws.readyState !== WebSocket.OPEN) {
      if (ws === null || !ws.readyState) {
        log('green', 'WebSocket connecting');
      } else {
        log('red', 'WebSocket is not open:', ws.readyState);

        if (ws.readyState === WebSocket.CLOSED) {
          // reconnect websocket
          log('green', 'Reconnect WebSocket');
          connectWebSocket();
        }
      }
      return;
    }

    if (msg.auth_key) {
      log('blue', 'Sending message:', msg);
      ws.send(JSON.stringify(msg));
    }
    

  }

  
  const sendMessage = () => {
    if (message.value === '') {
      return;
    }
    log('blue', 'sendMessage', message.value);

    const message_id = generateUUID();

    // html to escape
    message.value = message.value.replace(/</g, '&lt;').replace(/>/g, '&gt;');

    postMessage(
      {
        'auth_key': props.token,
        'message_text': message.value,
        'message_type': 'normal-message',
        'message_id': message_id,
        'client_type': props.type
      }
    );

    const date = moment().utc(0).format('YYYY-MM-DD HH:mm:ss');

    messages.value.push({
      message_id: message_id,
      message_status: 1,
      avatar: props.type === 'creator' ? props.user.avatar : props.merchant.logo,
      name: props.type === 'creator' ? props.user.name : props.merchant.name,
      name: props.user.name,
      message_text: message.value,
      isSelf: true,
      client_type: props.type,
      created_at: date,
      updated_at: date,
      isNew: true,
    });

    localforage.setItem(prefix + uid, JSON.stringify(messages.value));
    message.value = '';
  }

  const syncMessages = (data) => {
    loading.close();
    const list = data.data;
    if (!isEnd.value) isEnd.value = (list.length < 20);

    if (isEnd.value) {
      localforage.setItem(prefix + uid + '_end', 1);
    }

    log('blue', 'Load messages is end', isEnd.value);

    setTimeout(() => {
      isLoadingMore.value = false;
    }, 500);
    if (list.length === 0) {
      return;
    }

    list.forEach(item => {

      if (messages.value.find((msg) => msg.message_id === item.message_id)) {
        // log('red', 'message exists ' + item.message_id);
        return;
      }

      log('green', 'new message ' + item.message_id);
    
      const isSelf = item.client_type === props.type;

      messages.value.unshift({
        message_id: item.message_id,
        message_status: item.message_status,
        avatar: item.client_type === 'creator' ? props.user.avatar : props.merchant.logo,
        name: item.client_type === 'creator' ? props.user.name : props.merchant.name,
        message_text: item.message_text,
        isSelf: isSelf,
        client_type: item.client_type,
        updated_at: item.updated_at,
        created_at: item.created_at,
      });

      messages.value = messages.value.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
    });

    // if (unread_messages.length > 0) {
    //   markAsRead(unread_messages);
    // }

    last_date.value = messages.value[0].created_at;
    localforage.setItem(prefix + uid, JSON.stringify(messages.value));
  }

  const onOpen = () => {
    log('green', 'WebSocket connected');
    statusType.value = 'connected';
    emit('get-message-history');
    postMessage(
      {
        'auth_key': props.token,
        'message_text': '',
        'message_type': 'normal-message',
      }
    );
  }

  const onClose = () => {
    log('red', 'WebSocket disconnected');
    statusType.value = 'closed';
    loading.close();
  }

  const onError = (error) => {
    console.error('red', 'WebSocket error', error);
    statusType.value = 'error';
    loading.close();
  }

  const onReconnect = () => {
    log('green', 'WebSocket reconnecting');
    statusType.value = 'reconnecting';
  }

  const onMessage = (event) => {
    log('blue', 'Receive message:', event.data, props.user.id, props.token);

    if (event.data) {
      const data = JSON.parse(event.data);
      if (data.server_response_message_type === 'send-message-ok' && data.message_text !== '') {
        const isSelf = data.client_type === props.type;

        if (messages.value.find((msg) => msg.message_id === data.message_id)) {
          const currentMessage = messages.value.find((msg) => msg.message_id === data.message_id);
          currentMessage.isNew = false;
          log('green', 'Message post success', currentMessage.message_id);
        } else {
          if (data.client_type !== props.type) {
            markAsRead([data.message_id]);
          }

          messages.value.push({
            message_id: data.message_id,
            message_status: data.message_status,
            avatar: data.client_type === 'creator' ? props.user.avatar : props.merchant.logo,
            name: data.client_type === 'creator' ? props.user.name : props.merchant.name,
            message_text: data.message_text,
            isSelf: isSelf,
            client_type: data.client_type,
            updated_at: data.updated_at,
            created_at: data.created_at,
          });
        }

        localforage.setItem(prefix + uid, JSON.stringify(messages.value));

        setTimeout(() => {
          // scroll to bottom
          content.value.scrollTop = content.value.scrollHeight;
        }, 100);
      } 
      
      // else if (data.server_response_message_type === 'server-heart-beat') {
      //   log('green', 'Heart beat');
      // }
    }
  }

  const markAsRead = (ids = []) => {
    log('Violet', 'Mark As Read', ids);

    ids.forEach((id) => {
      const message = messages.value.find((msg) => msg.message_id === id);
      if (message) message.message_status = 100;
    });

    postMessage(
      {
        'auth_key': props.token,
        'message_type': 'mark-as-read',
        'action_message_ids': ids
      }
    );
  }

  const retryMessage = (message_id) => {
    const message = messages.value.find((msg) => msg.message_id === message_id);
    postMessage(
      {
        'auth_key': props.token,
        'message_text': message.message_text,
        'message_type': 'normal-message',
        'message_id': message_id,
        'client_type': props.type
      }
    );
  }

  const generateUUID = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
      let r = Math.random() * 16 | 0,
        v = c == 'x' ? r : (r & 0x3 | 0x8);
      return v.toString(16);
    });
  }

  const handleScroll = () => {
    // log((content.value.scrollHeight - content.value.clientHeight) + content.value.scrollTop);
    if (isEnd.value === false && isLoadingMore.value === false && ((content.value.scrollHeight - content.value.clientHeight) + content.value.scrollTop) < 10) {
      isLoadingMore.value = true;
      emit('get-message-history', messages.value[0].message_id);
    }
  }

  const openAnnouncement = (id) => {
    announcementDetail.value.open(id);
  }

  defineExpose({
    syncMessages,
    openAnnouncementList: () => announcementList.value.open(),
  });

  onMounted(() => {
    // connectWebSocket();
    content.value.addEventListener('scroll', handleScroll);

    localforage.getItem(prefix + uid).then((value) => {
      // log('get messages');
      if (value) {
        const items = JSON.parse(value);

        // init messages, set avatar and name for display
        items.forEach(item => {
          const isSelf = item.client_type === props.type;
          item.isSelf = isSelf;
          item.avatar = item.client_type === 'creator' ? props.user.avatar : props.merchant.logo;
          item.name = item.client_type === 'creator' ? props.user.name : props.merchant.name;
        });

        messages.value = items;
      }


      connectWebSocket();
    });
  });

  onUnmounted(() => {
    if (content.value) content.value.removeEventListener('scroll', handleScroll);
    if (ws) {
      ws.close();
      ws = null;
    }
  });
</script>

<style lang="scss">
  .message-wrap {
    flex: 1;
    display: grid;
    grid-template-rows: auto 1fr auto;
    background-color: var(--Bg-01);

    .message-header {
      background-color: var(--Bg-light);
    }

    .message-content {
      flex: 1;
      overflow-y: auto;
      background-color: var(--Bg-01);
      display: flex;
      flex-direction: column-reverse;
      position: relative;
      mask: linear-gradient(0deg, rgba(0, 0, 0, 1) calc(100% - 20px), rgba(0, 0, 0, 0) 100%);

      .message-list {
        padding: 24px 32px;
        position: relative;
        display: flex;
        flex-direction: column;
        background-color: var(--Bg-01);


        // .hr {
        //   border-top: 1px solid var(--Black-20);
        //   margin: 24px 0;
        //   overflow: hidden;
        //   clear: both;
        // }
      }

      .hr-date {
        text-align: center;
        margin: 24px 0;
        text-align: center;

        time {
          font: var(--medium-12);
          color: var(--Text-default);
          padding: 0 8px;
        }
      }

      .hr-end {
        text-align: center;
        margin: 24px 32px;
        text-align: center;
        font: var(--book-16);
        color: var(--Text-primary);
        order: 1;
        flex: 1;

        &::after {
          content: '';
          display: block;
          width: 100%;
          height: 1px;
          background-color: var(--Black-20);
          margin-top: 24px;
        }
      }

      .loading-btn {
        // position: absolute;
        // left: 0;
        // top: 24px;
        // width: 100%;
        position: relative;
        margin: 24px 0;

        .ui-loading-mask {
          background-color: transparent;
        }
      }
    }

    .message-footer {
      display: flex;
      align-items: center;
      padding: 16px 0;
      background-color: var(--Bg-light);

      .error-ico {
        color: var(--Red-30);
        cursor: pointer;
        margin-right: 16px;
        font: var(--medium-12);
        white-space: nowrap;
      }

      .#{$prefix}-button {
        margin-left: 6px;
        padding: 0;
        width: 42px;
        height: 40px;
        border: none !important;
        color: var(--Text-primary);

        svg {
          height: 20px;
          width: 20px;
        }

        &.is-disabled {
          color: var(--Black-40);
          background-color: var(--Bg-light);
        }
      }
    }

    .#{$prefix}-loading-spinner .#{$prefix}-loading-text {
      color: var(--Text-secondary);
      font: var(--book-12);
    }
  }

  .message-list-enter-active, .message-list-leave-active {
    transition: all 0.5s ease;
  }

  .message-list-enter-from, .message-list-leave-to {
    opacity: 0;
    transform: translateY(20px);
  }

</style>