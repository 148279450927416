<template>
    <el-drawer v-model="isShowModal" class="chatroom-drawer" :with-header="false" :destroy-on-close="true">
        <Loading class="static" v-if="isLoading" />
        <chat-messaging 
            v-else-if="token && currentUser.id" 
            :user="currentUser" 
            :merchant="merchant" 
            :token="token" 
            :ajax="ajax"
            type="merchant" 
            ref="chatRoom"
            :key="token" 
            @get-message-history="getMessageHistory"
        >
            <template #header>
                <div class="left">
                    <el-avatar :src="currentUser.avatar"></el-avatar>
                    <h2>{{ currentUser.name }}</h2>
                </div>
                <i class="close" @click="close">
                    <svg-icon name="close" />
                </i>
            </template>
        </chat-messaging>
    </el-drawer>
</template>

<script setup>
    import { ref, getCurrentInstance, onMounted } from 'vue';
    import Loading from '../ui/Loading.vue';
    import { ElDrawer, ElAvatar } from 'element-plus';
    import log from '../../utils/Log';
    import { useStore } from 'vuex';
    import ChatMessaging from '@/components/chat/ChatMessaging.vue';

    const isShowModal = ref(false);
    const store = useStore();
    const app = getCurrentInstance();
    const ajax = app.appContext.config.globalProperties.$ajax;
    const EventBus = app.appContext.config.globalProperties.EventBus;
    const currentUser = ref({});
    const merchant = ref({
        id: store.state.user_data.merchant_account_id,
        name: store.state.user_data.merchant_account.merchant_display_name,
        logo: store.state.user_data.merchant_account.brand_logo
    });
    const isLoading = ref(true);
    const token = ref('');
    const last_active_date = ref('');
    const chatRoom = ref(null);
    
    const getToken = (id) => {
        ajax.post('/chatroom/get-auth-key', { creator_portal_user_id: id.toString() }).then((res) => {
            token.value = res.data.data.auth_key;
            last_active_date.value = res.data.data.creator_last_active_time;
            isLoading.value = false;
            // log(res.data.data.auth_key);
        });
    };

    const getMessageHistory = (id = '') => {
        let api = '/chatroom/get-message-history?creator_portal_user_id=' + currentUser.value.id;

        if (id) {
            api += '&last_message_id=' + id;
        }

        ajax.get(api).then(res => {
            chatRoom.value.syncMessages(res.data.data);
        });
        // let data = {};
        // data.data = [];

        // chatRoom.value.syncMessages(data);
    };



    const open = () => {
        // log('open chat');
        // log('red', 'user', currentUser.value);

        isShowModal.value = true;
        getToken(currentUser.value.id);

    }

    const close = () => {
        isShowModal.value = false;

        setTimeout(() => {
            currentUser.value = {};
            token.value = '';
            last_active_date.value = '';
            isLoading.value = true;
        }, 700);
    }

    onMounted(() => {
        EventBus.off('open-chat');

        EventBus.on('open-chat', (user) => {
            log('red', 'user', user);

            currentUser.value = {
                id: user.creator_portal_user_id,
                name: user.first_name + ' ' + user.last_name,
                avatar: user.userProfile.avatar,
            }
            open();
        })
    });

    defineExpose({
        open,
        close
    });
</script>

<style lang="scss">
    .el-overlay {
        background-color: transparent !important;
    }

    .chatroom-drawer.el-drawer {
        width: calc(100vw - 300px) !important;
        box-shadow: -6px 0px 8px rgba(0, 0, 0, 0.1);
        
        .message-wrap {
            height: 100%;
            margin: 0 24px;

            .message-header {
                padding: 16px 0;
                display: flex;
                align-items: center;

                .left {
                    display: flex;
                    align-items: center;
                    flex: 1;

                    .el-avatar {
                        margin-right: 12px;
                    }

                    h2 {
                        font: var(--medium-20);
                        color: var(--Text-primary);
                    }
                }

                .close {
                    cursor: pointer;
                    width: 14px;
                    height: 14px;

                    svg {
                        width: 100%;
                        height: 100%;
                        fill: var(--Text-default);
                    }

                    &:hover {
                        svg {
                            fill: var(--Text-primary);
                        }
                    }
                }
            }
        }
    }

</style>